.cursor-pointer {
  cursor: pointer;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-4 {
  border-radius: 4px;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.pl10 {
  padding-left: 10px;
}

.pr10 {
  padding-right: 10px;
}

.wd-80 {
  min-width: 80%;
  max-width: 80%;
}

.wd-19 {
  min-width: 19%;
  max-width: 19%;
  height: 35px;
}

.waiting {
  color: #fffa7a;
}

.rejected {
  color: #ff7272;
}

.accepted {
  color: #85ff7a;
}

.custom-toggle.react-toggle--checked .react-toggle-track {
  background-color: #607d8b;
}

.custom-control.custom-checkbox {
  z-index: 0;
}

.custom-checkbox .custom-control-label::before {
  background-color: transparent;
  width: 23px;
  height: 23px;
  border: 3px solid #8e8e8e;
  top: 0;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8e8e8e;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox .custom-control-label::after {
  width: 23px;
  height: 23px;
  top: 0;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: transparent;
  border-color: #8e8e8e;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  border: 3px solid #8e8e8e;
}

.custom-input {
  background-color: #d7d7d7;

  &:focus {
    background-color: #d7d7d7;
    border: inherit;
    box-shadow: none;
  }
}

.react-select-container,
.react-select-portal {
  .react-select__control {
    border-radius: 10px;
    background-color: #66696b;
    border: none;

    .react-select__value-container {
      .react-select__input {
        color: #d8dadf;
      }

      .react-select__single-value {
        color: #d8dadf;
      }

      .react-select__placeholder {
        color: #d8dadf;
      }
    }

    .react-select__indicators {
      .react-select__indicator {
        color: #d8dadf;
      }
    }
  }

  .react-select__menu {
    background-color: #66696b;

    .react-select__menu-list {
      .react-select__option {
        cursor: pointer;

        &--is-selected {
          background-color: #66696b;
        }

        &--is-focused {
          background-color: #55585a;
        }
      }
    }
  }
}

.delivery-link {
  text-decoration: none;
  color: white;
  transition: opacity 0.2s;
}

.delivery-link:hover {
  text-decoration: none;
  opacity: 0.7;
  color: white;
}

.card-offer {
  min-height: 310px;
}

.delivery-status {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
}

.task-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px auto;
}

.task-form input {
  width: 80%;
  height: 40px;
}

.task-form button {
  width: 20%;
  margin-left: 10px;
}

.breadcrumb {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.breadcrumb a:hover {
  text-decoration: none;
}

.last {
  pointer-events: none;
  text-decoration: none;
  color: white;
  cursor: default;
}

.breadcrumb .last:hover {
  color: white;
}

// Midle Screens break-point
@media (max-width: 991px) {
  .breadcrumb {
    display: none;
  }
}

// mobile break-point
@media (max-width: 576px) {
  .task-form {
    flex-direction: column;
  }
  .task-form input {
    width: 100%;
  }
  .task-form button {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}
