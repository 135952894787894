$primary: #ff8400;
$black-russian: #1c1c1e;
$bastille: #2c2c2e;
$light: #f2f2f9;
$tomato: #f9443a;
$payne: #3a3b3c;
$primaryBlue: #5885f5;

:root {
  --black-russian: $black-russian;
  --bastille: $bastille;
}

$body-bg: $black-russian;
$body-color: $light;

$theme-colors: (
  "primary": $primary,
  "dark": $bastille,
  "danger": $tomato,
  "secondary": $payne,
  "light": #d8dadf,
);

@import "~bootstrap/scss/bootstrap.scss";
