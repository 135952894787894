.navbar.navbar-base {
  height: 80px;
  padding-left: 200px;
  padding-right: 300px;
}

.notification-icon {
  width: 24px;
  height: 24px;
}

.logo-header {
  width: 190px;
}

@media (max-width: 576px) {
  .logo-header {
    width: 150px;
  }
}
